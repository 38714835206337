
// @ts-ignore
import PetloveLogo from '@petlove/webstore-components/src/Draws/logoPetlove.vue';
// @ts-ignore
import PetloveLogoWhite from '@petlove/webstore-components/src/Draws/logoPetloveWhite.vue';
// @ts-ignore
import VetsmartLogoWhite from '@petlove/webstore-components/src/Draws/logoVetsmart.vue';
import { ref, computed, defineComponent } from '@vue/composition-api';
import { useNamespacedState } from 'vuex-composition-helpers';

import { privacyPageEvent } from '@/lib/analytics_events';
import { getSessionCookies } from '@/lib/login';
import { SSOState } from '@/store/sso/state';
import { WhitelabelState } from '@/store/whitelabel/state';

export default defineComponent({
  components: {
    PetloveLogo,
    PetloveLogoWhite,
    VetsmartLogoWhite
  },
  setup() {
    const showMenu = ref(false);
    const isFromEmail = ref(false);
    const isPrivacyPage = ref(false);

    const { cancelURI } = useNamespacedState<SSOState>('sso', ['cancelURI']);

    const { store: whitelabelStore } = useNamespacedState<WhitelabelState>(
      'whitelabel',
      ['store']
    );

    const isWhitelabel = computed(
      () => whitelabelStore.value?.isWhitelabel ?? false
    );

    const privacyPageUrl = computed(
      () => `/privacidade?${new URLSearchParams(window.location.search)}`
    );

    const emitPrivacyEvent = () => {
      if (!isPrivacyPage.value) return;
      privacyPageEvent('privacidade:link:voltar');
    };

    return {
      showMenu,
      isFromEmail,
      isPrivacyPage,
      cancelURI,
      isWhitelabel,
      whitelabelStore,
      privacyPageUrl,
      emitPrivacyEvent
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.isPrivacyPage = this.$route.name === 'privacidade';
        this.isFromEmail = this.$route.query.fromEmail === 'true';
      }
    }
  },
  methods: {
    controllMenuVisibility(event: MouseEvent | PointerEvent) {
      if (!this.$device.isMobile) return (this.showMenu = true);

      if (!(event instanceof PointerEvent)) return;
      this.showMenu ? (this.showMenu = false) : (this.showMenu = true);
    },
    async logout() {
      privacyPageEvent('botao:sair');
      const { sessionId } = getSessionCookies(this.$nuxtCookies);
      await this.$store.dispatch('sso/delete', [sessionId]);
      window.location.replace('/');
    }
  }
});
