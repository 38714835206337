import { render, staticRenderFns } from "./UserInformation.vue?vue&type=template&id=33b7585a&scoped=true&"
import script from "./UserInformation.vue?vue&type=script&lang=ts&"
export * from "./UserInformation.vue?vue&type=script&lang=ts&"
import style0 from "./UserInformation.vue?vue&type=style&index=0&id=33b7585a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b7585a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaAvatar: require('/app/node_modules/@petlove/caramelo-vue-components/src/Avatar/index.vue').default})
