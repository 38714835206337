
import { computed, defineComponent } from '@vue/composition-api';
import { useNamespacedState } from 'vuex-composition-helpers';

import { SSOState } from '@/store/sso/state';
import { WhitelabelState } from '@/store/whitelabel/state';

export default defineComponent({
  props: {
    gap: {
      type: Number,
      default: 16
    },
    hasBackground: {
      type: Boolean,
      default: false
    },
    avatarSize: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const { email } = useNamespacedState<SSOState>('sso', ['email']);

    const { store: whitelabelStore } = useNamespacedState<WhitelabelState>(
      'whitelabel',
      ['store']
    );

    const emailLabel = computed(() =>
      email.value.substring(0, email.value.indexOf('@'))
    );

    const avatarStyle = computed(() => {
      const sizeStyle = {
        fontSize: '1.6rem',
        width: `${props.avatarSize}px !important`,
        height: `${props.avatarSize}px !important`
      };

      const gapStyle = {
        marginRight: `${props.gap}px`
      };

      return {
        ...(props.gap && gapStyle),
        ...(props.avatarSize && sizeStyle)
      };
    });

    const isWhitelabel = computed(
      () => whitelabelStore.value?.isWhitelabel ?? false
    );

    return { emailLabel, avatarStyle, isWhitelabel };
  }
});
