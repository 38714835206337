import { render, staticRenderFns } from "./HeaderSSO.vue?vue&type=template&id=2caeaf19&scoped=true&"
import script from "./HeaderSSO.vue?vue&type=script&lang=ts&"
export * from "./HeaderSSO.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderSSO.vue?vue&type=style&index=0&id=2caeaf19&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2caeaf19",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaLink: require('/app/node_modules/@petlove/caramelo-vue-components/src/Link/index.vue').default,LayoutUserInformation: require('/app/packages/auth/components/layout/UserInformation.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default,CaCard: require('/app/node_modules/@petlove/caramelo-vue-components/src/Card/index.vue').default})
