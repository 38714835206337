// @ts-ignore
import { customEvent } from '@petlove/webstore-libs/analytics/events/customEvents';

export const normalizeActionText = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replaceAll(' ', '-')
    .toLowerCase();
};

export const privacyPageEvent = (action: string) => {
  customEvent('click', action, 'gerenciamento-de-sessao');
};
